import React from "react";
import { graphql } from "gatsby";

import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import PortableText from "@sanity/block-content-to-react"
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "../../styles/pages/about.module.css";
import * as sharedStyles from "../../styles/shared.module.css";

export const query = graphql`
  query AboutPageQueryEn {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      surgeonName: surgeonName_en
      title: title_en
      description: description_en
      keywords: keywords_en
    },
    about: sanityAbout {
      _rawTextEn
    }
  }
`;

const IndexPage = props => {
  const { data } = props;

  const site = data?.site;
  const about = data?.about

  return (
    <Layout isEn={true}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div style={{ display: 'flex' }}>
        <h2 className={sharedStyles.sectionTitle}>About me</h2>
      </div>
      <div className={styles.main}>
        <PortableText blocks={about._rawTextEn} />
      </div>
      {/* <div className={sharedStyles.transitionMargin}>
          <StaticImage src="../media/BackgroundShift.svg" objectFit="contain" alt="Background transition" className={sharedStyles.backgroundTransition} />
      </div> */}
    </Layout>
  );
};

export default IndexPage;
